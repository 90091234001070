// VARIABLES
@import "variables";

.mce-content-body {
  margin: 0.5rem;
  
  p,
  li,
  a {
    font-size: 1.375rem;
    line-height: 1.625;
  }

  .btn {
    display: inline-block;
    
    a {
      display: inline-block;
      border: 1px solid $color-gray;
      text-decoration: none;
      border-radius: 3px;
      padding: 0.5rem 0.75rem;
    }
  }
  
  .btn--primary a {
    background: $color-btn;
    color: $color-text--inverted;
  }
  
  .btn--secondary a {
    background: transparent;
    color: $color-btn;
		border: $base-border-width solid currentColor;
  }
  
  .text--attention {
    font-weight: 600;
  }
}
