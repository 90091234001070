// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// PUT YOUR OWN VARIABLES HERE and overwrite the nutshell
// --------------------------------------------------
// Avaliable Fonts:
//  font-family: Yanone Kaffeesatz, Roboto

//  IMPORTANT: remove the !default when a value is changed!!!

// General Colors
$color-gray-dark:         #111;
$color-gray:							#888;
$color-gray-light:  			#f2f2f2;

$color-blue:        hsla(205, 65%, 40%, 1);
$color-gold:        hsla(41, 63%, 54%, 1);
$color-gold-light:  hsla(41, 63%, 89%, 1);

$color-tint:       	hsla(205, 65%, 40%, 0.1);

$color-brand:       			$color-gold;
$color-highlight:					$color-brand;

// -----------------------------------------------------------
// Base Font Settings in der _page.scss für das <html> Element
// -----------------------------------------------------------
// zwei Schriftarten für body-text und headings unterscheiden
// (diese Variablen gibt es in der Nutshell nicht!!!)
$base-font-family-body:         "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-family-headers:      "Yanone Kaffeesatz", "Helvetica Neue", Helvetica, Arial, sans-serif; // used in _type.scss, overwrites _type.scss (nutshell)

// font-family:
$base-font-family:                $base-font-family-body;

// font-size:
$base-font-size--xs:      				0.875rem;  // Breakpoint kommt aus _responsive.scss --xs <= 549 px
$base-font-size:          				1rem;  // 16 px ist Browserdefault
$base-font-size--lg:      				1.125rem;  // eigene Ergänzung
$base-font-size--xl:      				1.25rem; // Breakpoint kommt aus _responsive.scss --xl >= 1100 px
// line-height
$base-line-height:          1.625 !default;
$base-font-weight:          200;
$base-font-weight-headers: 800; // used in _type.scss, overwrites _type.scss (nutshell)

// -----------------------------------------------------------
// Base Page Settings in der _page.scss für das <html> Element
// -----------------------------------------------------------
// background-color:
$color-page-background:   #fff !default; // used in _layout.scss (nutshell)
$color-text:              $color-blue;

// -----------------------------------------------------------
// other basic settings
// -----------------------------------------------------------
// Base Spacing Units
$base-spacing-unit:		1.625rem;

$base-spacing-unit--xs:       $base-spacing-unit / 4 !default;
$base-spacing-unit--sm:       $base-spacing-unit / 2 !default;

$base-spacing-unit--lg:       $base-spacing-unit * 2 !default;
$base-spacing-unit--xl:       $base-spacing-unit * 4 !default;

// Base Border Settings
$base-border-radius:          4px !default;
$base-border-width:           2px;
$base-border-color:           currentColor;

// Base Box Shadow
$base-img-shadow: 				0 0 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$base-overlay-background: hsla(205, 65%, 80%, 1);

// -----------------------------------------------------------
// OWN VARIABLES:
// -----------------------------------------------------------
// Links
$color-links: $color-highlight !default;

// Buttons
$color-btn:       $color-links !default;
$color-btn-text:  #fff !default;

// Bands
$color-band-highlight-background:     $color-highlight;
$color-band-tint-background:          $color-tint;
$color-band-dark-text:                #fff;
$color-band-dark-background:          hsla(205, 65%, 40%, 1);
$color-band-dark-background-gradient: radial-gradient(circle at 0% 0%, $color-band-dark-background 0%, darken($color-band-dark-background, 10%) 100%);


// IE Support
$ie9-support: true;


// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------
